import React, { useState } from 'react'
import { Link } from 'gatsby'

import DesktopSubMenu from './DesktopSubMenu'

export default function DesktopHeader({ setMenu, icons }) {
  const closeMenus = () => {
    setMenu(false)
  }

  const [subMenu, setSubmenu] = useState(false)

  const handleClick = () => {
    setSubmenu(!subMenu)
  }

  return (
    <>
      <header className='desktop-header'>
        <nav aria-label="ICS">
          <ul aria-label="ICS" role="menubar" id="menubar1">
            <li role="none">
              <Link to='/' onClick={closeMenus} role="menuitem">
                <img
                  src={icons.logo.image.publicURL}
                  alt='ICS Multimedia Logo'
                  className='desktop-header__logo'
                />
              </Link>
            </li>
            <div role="none" className="menu-links">
              <li role="none">
                <span role="menuitem" aria-haspopup="true" className='menu-links__products ics__h2' onClick={handleClick} style={{ color: subMenu ? 'var(--brand-primary)' : 'var(--brand-neutral-000)' }}>
                  Products
                </span>
                <div role="none" className="menu-links__link-container" style={{ backgroundColor: subMenu && 'var(--brand-neutral-000)' }} />
                <DesktopSubMenu trackerIcons={icons.trackers} subMenu={subMenu} setSubmenu={setSubmenu} />
              </li>
              <li role="none">
                <Link to='/about' className='ics__h2' role="menuitem">About</Link>
                <div role="none" className="menu-links__link-container" />
              </li>
              <li role="none">
                <Link to='/contact' className='ics__h2' role="menuitem">Contact</Link>
                <div role="none" className="menu-links__link-container" />
              </li>
            </div>
          </ul>
        </nav>
      </header>
    </>
  )
}
