import React, { useState, useEffect } from 'react'

import Header from './header/Header'
import SEO from './SEO'
import Footer from './Footer'

export default function Layout({ children, title, url, page }) {
  const [menu, setMenu] = useState(false)
  const [subMenu, setSubMenu] = useState(false)

  useEffect(() => {
    scrollLock()
  }, [menu])

  useEffect(() => {
    setMenu(false)
    setSubMenu(false)
  }, [])

  const menuClick = () => {
    if (!menu) {
      setSubMenu(false)
    }

    setMenu(!menu)
  }

  const subMenuClick = () => {
    setSubMenu(!subMenu)
  }

  const scrollLock = () => {
    if (menu) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'visible'
    }
  }

  return (
    <div className={`page ${page && page}`}>
      <SEO title={title} url={url} />
      <Header
        menu={menu}
        setMenu={setMenu}
        menuClick={menuClick}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        subMenuClick={subMenuClick}
      />
      <main
        id='content'
        className='content'
      >
        {children}
      </main>
      <Footer />
    </div>
  )
}
