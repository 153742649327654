import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

export default function Footer() {
  const {
    dataJson
  } = useStaticQuery(graphql`
    query Footer {
      dataJson {
        header {
          logo {
            id
            name
            image {
              publicURL
            }
          }
          trackers {
            id
            name
            title
            slug
          }
        }
        footer {
            nav {
              internal {
                id
                title
                link
              }
              external {
                id
                title
                link
              }
            }
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
              }
            }
        }
        contactInfo {
          australia {
            title
            addressLine1
            addressLine2
            addressLine3
            phone
            phoneLink
            link
          }
          australia_2 {
            addressLine1
            addressLine2
            addressLine3
            link
          }
          singapore {
            title
            addressLine1
            addressLine2
            addressLine3
            link
          }
        }
      }
    }
  `)
  return (
    <footer className='footer'>
      <BackgroundImage fluid={dataJson.footer.image.childImageSharp.fluid} className='footer__content'>
        <img
          src={dataJson.header.logo.image.publicURL}
          alt='ICS Multimedia Logo'
          className='logo'
        />
        <nav className='footer__nav'>
          <ul>
            {dataJson.footer.nav.internal.map(link => <li key={link.id}><Link to={link.link} className="ics__p-hyperlink">{link.title}</Link></li>)}
            {dataJson.footer.nav.external.map(link => <li key={link.id}><a className="ics__p-hyperlink" href={link.link} target="_blank" rel="noreferrer noopener">{link.title}</a></li>)}
          </ul>
          <ul>
            {dataJson.header.trackers.map(tracker => <li key={tracker.id}><Link to={tracker.slug} className="ics__p-hyperlink">{tracker.title}</Link></li>)}
          </ul>
        </nav>
        <div className='footer__contact'>
          <div>
            <h3 className="ics__p-bold">Australia</h3>
            <p>
              <a className="ics__p-hyperlink-hide" href={dataJson.contactInfo.australia.link} target="_blank" rel="noreferrer noopener">
                {dataJson.contactInfo.australia.addressLine1}<br />
                {dataJson.contactInfo.australia.addressLine2}<br />
                {dataJson.contactInfo.australia.addressLine3}<br />
              </a>
              <a className="ics__p-hyperlink-hide" href={dataJson.contactInfo.australia.phoneLink} target="_blank" rel="noreferrer noopener">
                {dataJson.contactInfo.australia.phone}
              </a>
            </p>
          </div>
          <div>
            <p>
              <a className="ics__p-hyperlink-hide" href={dataJson.contactInfo.australia_2.link} target="_blank" rel="noreferrer noopener">
                {dataJson.contactInfo.australia_2.addressLine1}<br />
                {dataJson.contactInfo.australia_2.addressLine2}<br />
                {dataJson.contactInfo.australia_2.addressLine3}<br />
              </a>
            </p>
          </div>
          <div>
            <h3 className="ics__p-bold">Singapore</h3>
            <p>
              <a className="ics__p-hyperlink-hide" href={dataJson.contactInfo.singapore.link} target="_blank" rel="noreferrer noopener">
                {dataJson.contactInfo.singapore.addressLine1}<br />
                {dataJson.contactInfo.singapore.addressLine2}<br />
                {dataJson.contactInfo.singapore.addressLine3}
              </a>
            </p>
          </div>
        </div>
        <div className='footer__small'>
          <small className="ics__p-small">Geospatial data from <a href="https://data.linz.govt.nz/" target="blank" className="ics__p-small-hyperlink">LINZ</a>, <a href="https://github.com/NZGovt/DOC" target="_blank" rel="noreferrer noopener" className="ics__p-small-hyperlink">DOC</a> and <a href="https://services.thelist.tas.gov.au/" target="_blank" rel="noreferrer noopener" className="ics__p-small-hyperlink">LIST</a> are made avalible under a <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer noopener" className="ics__p-small-hyperlink">Creative Commons Attribution Licence</a>.</small>
          <br />
          <small className="ics__p-small">Copyright © ICS, {new Date().getFullYear()}. All rights reserved.</small>
        </div>
      </BackgroundImage>
    </footer>
  )
}
