import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import MobileSubMenu from './MobileSubMenu'

export default function MobileHeader({
  menu,
  setMenu,
  menuClick,
  subMenu,
  setSubMenu,
  subMenuClick,
  icons,
  contactInfo: { australia, australia_2, singapore }
}) {
  const closeMenus = () => {
    menuClick()
    setMenu(false)
    setSubMenu(false)
  }

  const { dataJson: { footer } } = useStaticQuery(graphql`
    query MobileFooter {
      dataJson {
        footer {
          image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }`)
  return (
    <>
      <header className={`mobile-header ${!menu && 'drop-shadow'}`}>
        <Link to='/' onClick={closeMenus}>
          <img
            src={icons.logo.image.publicURL}
            alt='ICS Multimedia Logo'
            className='logo'
          />
        </Link>

        {!menu ? (
          <img
            src={icons.burger.image.publicURL}
            alt='Menu Icon'
            onClick={menuClick}
          />
        ) : (
            <span
              onClick={closeMenus}
              className='ics__h2 mobile-close'
              role='button'
              tabIndex={0}>
              Close
            </span>
          )}
      </header>
      <section className={`mobile-menu ${menu ? 'fade-in' : 'fade-out'}`}>
        <div className='mobile-menu__overlay'>
          <nav className='mobile-menu__nav'>
            <ul>
              <li className='ics__h2'>
                <Link
                  to='/'
                  onClick={menuClick}
                  className='mobile-menu__link'>
                  Home
              </Link>
              </li>
              <li className='dropdown'>
                <button className='ics__h2' onClick={subMenuClick}>Products</button>
                <MobileSubMenu subMenu={subMenu} trackerIcons={icons.trackers} />
              </li>
              <li
                className={`ics__h2 ${subMenu ? 'mobile--down' : 'mobile--up'
                  }`}>
                <Link to='/about' className='mobile-menu__link'>
                  About
              </Link>
              </li>
              <li className={`ics__h2 ${subMenu ? 'mobile--down' : 'mobile--up'
                }`}>
                <Link to='/contact' className='mobile-menu__link'>
                  Contact
              </Link>
              </li>
            </ul>
          </nav>
          <footer
            className={`mobile-menu__footer ${subMenu ? 'mobile--down' : 'mobile--up'
              }`}>
            <BackgroundImage fluid={footer.image.childImageSharp.fluid} style={{ backgroundColor: 'var(--brand-neutral-1000)', backgroundBlendMode: 'multiply' }}>
              <img
                src={icons.logo.image.publicURL}
                alt='ICS Multimedia Logo'
                className='logo'
              />
              <h2 className='ics__h3'>{australia.title}:</h2>
              <p>
                <a className="ics__p-hyperlink-hide" href={australia.link} target="_blank" rel="noreferrer noopener">
                  {australia.addressLine1}<br />
                  {australia.addressLine2}<br />
                  {australia.addressLine3}<br />
                </a>
                <a className="ics__p-hyperlink-hide" href={australia.phoneLink} target="_blank" rel="noreferrer noopener">
                  {australia.phone}
                </a>
              </p>
              <h2 className='ics__h3'>{singapore.title}:</h2>
              <p>
                <a className="ics__p-hyperlink-hide" href={singapore.link} target="_blank" rel="noreferrer noopener">
                  {singapore.addressLine1}
                  {singapore.addressLine2}
                  {singapore.addressLine3}
                </a>
              </p>
            </BackgroundImage>
          </footer>
        </div>
      </section>
    </>
  )
}
