import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

export default function SEO({ title, url }) {
  const {
    site: {
      siteMetadata: {
        title: siteTitle,
        description,
        author,
        developer,
        siteUrl,
      },
    },
  } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
          author
          developer
          siteUrl
        }
      }
    }
  `)
  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={`${title} | ${siteTitle} `}
      meta={[
        { name: 'description', content: description },
        { name: 'author', content: author },
        { name: 'develoer', content: developer },
        {
          name: 'keywords',
          content: 'ICS',
        },
        {
          name: 'robots',
          content: `index, follow`,
        },
        {
          name: 'googlebot',
          content: `index, follow`,
        },
      ].concat([])}>
      <link rel='canonical' href={`${siteUrl}${url && url}`} />
    </Helmet>
  )
}
