import React from 'react'
import { Link } from 'gatsby'

export default function DeskropSubMenu({ subMenu, trackerIcons }) {
  return (
    <ul
      className={`${subMenu ? 'mobile-sub-down' : 'mobile-sub-up'}`}
      id='mobile-sub'>
      {trackerIcons.map((icon) => (
        <li key={icon.id}>
          <Link to={icon.slug} className='mobile-header-nav__sublink'>
            <img src={icon.image.publicURL} alt={`${icon.name} Icon`} />
            <span className="ics__p-bold">{icon.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  )
}
