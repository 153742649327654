import React from 'react'
import { Link } from 'gatsby'

export default function DeskropSubMenu({ trackerIcons, subMenu, setSubmenu }) {
  return (
    <ul className='desktop-nav' role="menubar" id="menubar2" aria-label="Products" aria-selected={subMenu}>
      {trackerIcons.map((icon) => (
        <li key={icon.id} role="none">
          <Link to={icon.slug} className='desktop-nav__tracker' role="menuitem" onClick={() => setSubmenu(false)}>
            <img src={icon.image.publicURL} alt={`${icon.name} Icon`} />
            <span className='ics__h2'>{icon.title}</span>
          </Link>
        </li>
      ))}
    </ul>
  )
}
