import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import MobileHeader from './MobileHeader'
import DesktopHeader from './DesktopHeader'

export default function Header({
  menu,
  setMenu,
  menuClick,
  subMenu,
  setSubMenu,
  subMenuClick
}) {
  const {
    dataJson: { header: icons, contactInfo },
  } = useStaticQuery(graphql`
    query TrackerIcons {
      dataJson {
        header {
          burger {
            id
            name
            image {
              publicURL
            }
          }
          logo {
            id
            name
            image {
              publicURL
            }
          }
          trackers {
            id
            name
            title
            image {
              publicURL
            }
            slug
          }
        }
        contactInfo {
          australia {
            title
            addressLine1
            addressLine2
            addressLine3
            phone
          }
          australia_2 {
            addressLine1
            addressLine2
            addressLine3
          }
          singapore {
            title
            addressLine1
            addressLine2
            addressLine3
          }
        }
      }
    }
  `)
  return (
    <>
      <MobileHeader
        menu={menu}
        setMenu={setMenu}
        menuClick={menuClick}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        subMenuClick={subMenuClick}
        icons={icons}
        contactInfo={contactInfo}
      />
      <DesktopHeader
        menu={menu}
        setMenu={setMenu}
        menuClick={menuClick}
        subMenu={subMenu}
        subMenuClick={subMenuClick}
        icons={icons}
      />
    </>
  )
}
